import axios from "axios";
import qs from "qs";
import { navigate } from "gatsby";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
const TIMEOUT = 180000;

// const URL_ENDPOINT = window.location.protocol + "//" + window.location.host;
// const URL_ENDPOINT = 'http://localhost:8000';
const URL_ENDPOINT = "";
let URL_ENDPOINT_API = "/api";
// console.log("process.env.NODE_ENV", process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
  URL_ENDPOINT_API = "http://localhost:9000/api";
}

const LINE_CLI_ID = "1656971019";
const LINE_CLI_SECRET = "9b072f63856d065493caa506fbf362e8";

const LINE_REDIRECT = "https://isee.inforvation.systems" + "/processlinelogin";

const checkTokenExpire = () => {
  let token = localStorage.getItem("tok");
  if (!token) return false;
  let decoded = jwt_decode(token);
  let now = new Date().getTime() / 1000;
  if (decoded.exp < now) return false;
  return true;
};

const API = async ({ method = "post", url = null, data = null, headers = null, timeout = TIMEOUT }) => {
  try {
    if (headers && headers?.Authorization && !checkTokenExpire()) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "เซสชั่นหมดอายุ โปรดเข้าสู่ระบบให้อีกคร้ัง",
        icon: "warning",
        confirmButtonColor: "#038967",
        confirmButtonText: "ตกลง",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          onLogout();
        }
      });
      return Promise.reject({ message: "Token Error 401" });
    }

    let axiosCall = axios.CancelToken.source();
    setTimeout(() => {
      axiosCall.cancel(`ไม่สามารถเชื่อมต่อเครือข่ายได้ในขณะนี้(TIME OUT)`);
    }, timeout);
    let res = await axios({ method, url: url, data, headers, cancelToken: axiosCall.token });
    if (!res.data.ok) return new Promise.reject(new Error(res.data.message));
    else return res;
  } catch (err) {
    if (err.message === "Network Error") {
      return Promise.reject({ message: "ไม่สามารถเชื่อมต่ออินเตอร์เน็ต" });
    } else if (err.response.status === 401) {
      Swal.fire({
        title: "พบข้อผิดพลาด",
        text: "โปรดเข้าสู่ระบบเพื่อดำเนินการต่อ",
        icon: "error",
        confirmButtonColor: "#038967",
        confirmButtonText: "ตกลง",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          onLogout();
        }
      });
      return Promise.reject({ message: "Token Error 401" });
    } else if (err.response.status === 403) {
      // onLogout()
      Swal.fire({
        title: "พบข้อผิดพลาด",
        text: "ไม่สามารถเข้าถึงข้อมูลได้",
        icon: "error",
        confirmButtonColor: "#038967",
        confirmButtonText: "ตกลง",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          onLogout();
        }
      });
      return Promise.reject({ message: "Token Error 401" });
    } else {
      return Promise.reject(err);
    }
  }
};

const getUserForManage = async () => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/get-user-manage`,
      data: {},
    });
    return response.data;
  } catch (err) {
    return Promise.reject(JSON.stringify(err.message));
  }
};

const getActiveRole = () => {
  let res = localStorage.getItem("role_active");
  return res;
};

const getProfileDb = async (user) => {
  try {
    // if (!user) return;
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/get-profile`,
      data: JSON.stringify({}),
    });
    return response.data;
  } catch (err) {
    return Promise.reject(JSON.stringify(err.message));
  }
};

const getUserInfo = async () => {
  try {
    // if (!user) return;
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/get-user-roles`,
      data: JSON.stringify({}),
    });
    response.data.role_active = localStorage.getItem("role_active");
    return response.data;
  } catch (err) {
    return Promise.reject(JSON.stringify(err.message));
  }
};

const changeUserRole = async (body) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/change-role`,
      data: JSON.stringify(body),
    });
    localStorage.setItem("role_active", body.roles);
    return response.data;
  } catch (err) {
    return Promise.reject(JSON.stringify(err.message));
  }
};

const getBorder = async (body) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/border`,
      data: body,
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const getSchool = async (body) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/get-school`,
      data: body,
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const priority_role = {
  eef: 1,
  partner: 2,
  "public-login": 5,
  public: 6,
  representative: 4,
  partner_province: 3,
};
const auth = async (credential, remember) => {
  try {
    let response = await API({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      url: `${URL_ENDPOINT_API}/login`,
      data: {
        ...credential,
      },
    });
    localStorage.setItem("tok", response.data.to);
    const role = localStorage.getItem("role_active");
    if (!role) {
      let role_rank = ["public-login"];
      if (response.data.roles.length > 0) {
        role_rank = response.data.roles.sort((a, b) => {
          return (priority_role[a] || 999) - (priority_role[b] || 999);
        });
      }
      localStorage.setItem("role_active", role_rank[0]);
    }
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const redirectLineLogin = () => {
  let client_id = LINE_CLI_ID;
  let redirect_uri = LINE_REDIRECT;
  let state = "iseelogin";
  navigate(
    `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile%20openid%20email`
  );
};

const redirectLineConnect = () => {
  let client_id = LINE_CLI_ID;
  let redirect_uri = LINE_REDIRECT;
  let state = "isee_line_connect";
  navigate(
    `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile%20openid%20email`
  );
};

const authLineGetProfile = async (code, state) => {
  const data_token = qs.stringify({
    grant_type: "authorization_code",
    code: code,
    redirect_uri: LINE_REDIRECT,
    client_id: LINE_CLI_ID,
    client_secret: LINE_CLI_SECRET,
  });

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  try {
    let res_token = await axios.post("https://api.line.me/oauth2/v2.1/token", data_token, headers);
    let id_token = res_token.data.id_token;

    const data_verify = qs.stringify({
      id_token: id_token,
      client_id: LINE_CLI_ID,
    });

    let res_id = await axios.post("https://api.line.me/oauth2/v2.1/verify", data_verify, headers);

    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${URL_ENDPOINT_API}/linelogin`,
      data: { user_id: res_id.data.sub },
    });
    if (response.data.message === "Login Success") {
      localStorage.setItem("img_profile", res_id.data.picture);
      localStorage.setItem("tok", response.data.to);
    }
    response.data.line = {};
    response.data.line.name = res_id.data.name;
    response.data.line.email = res_id.data.email;
    response.data.line.img = res_id.data.picture;
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const authLineToEnable = async (code, state) => {
  const data_token = qs.stringify({
    grant_type: "authorization_code",
    code: code,
    redirect_uri: LINE_REDIRECT,
    client_id: LINE_CLI_ID,
    client_secret: LINE_CLI_SECRET,
  });

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  try {
    let res_token = await axios.post("https://api.line.me/oauth2/v2.1/token", data_token, headers);
    let id_token = res_token.data.id_token;

    const data_verify = qs.stringify({
      id_token: id_token,
      client_id: LINE_CLI_ID,
    });

    let res_id = await axios.post("https://api.line.me/oauth2/v2.1/verify", data_verify, headers);

    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/lineconnect`,
      data: { user: localStorage.getItem("user"), lineid: res_id.data.sub },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    console.log(err.message);
    if (err.response) {
      console.log(err.response.data.message);
      if (err.response.data.message === "Line Connect 400 Bad request") {
        return Promise.reject("Line Connect Error");
      } else if (err.response.data.message === "User Not Found") {
        onLogout();
      } else if (
        err.response.data.message === "User Not Match" ||
        err.response.data.message === "jwt must be provided" ||
        err.response.data.message === "invalid token" ||
        err.response.data.message === "jwt expired" ||
        err.response.data.message === "jwt malformed"
      ) {
        onLogout();
      } else {
        return Promise.reject(JSON.stringify(err.message));
      }
    } else {
      console.log(err.message);
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const register = async (data) => {
  try {
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${URL_ENDPOINT_API}/register`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    let error_message = null;
    if (err.response) {
      error_message = err.response.data.message.split("|");
      return Promise.reject(error_message.filter((item) => item !== ""));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const registerCaptcha = async (data) => {
  try {
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${URL_ENDPOINT_API}/register-captcha`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    console.log("err", err.response);
    console.log("err", err.response.data);
    console.log("err", err.response.data.isRecaptchaAgain);
    let error_message = null;
    if (err.response) {
      if (err.response.data.isRecaptchaAgain) {
        return Promise.reject(err.response.data);
      } else {
        error_message = err.response.data.message.split("|");
        return Promise.reject(error_message.filter((item) => item !== ""));
      }
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const registerByEmail = async (data, token) => {
  try {
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", authorization: token },
      url: `${URL_ENDPOINT_API}/register-by-email`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    let error_message = null;
    if (err.response) {
      error_message = err.response.data.message.split("|");
      return Promise.reject(error_message.filter((item) => item !== ""));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const sendMailRegister = async (data) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", authorization: token },
      url: `${URL_ENDPOINT_API}/send-email-register`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    let error_message = null;
    if (err.response) {
      error_message = err.response.data.message.split("|");
      return Promise.reject(error_message.filter((item) => item !== ""));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const resetpass = async (data) => {
  try {
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${URL_ENDPOINT_API}/send-email-resetpass`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === "Email require 400 Bad request") {
        return Promise.reject("รีเซ็ตรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้งภายหลัง");
      } else if (err.response.data.message === "Email not found") {
        return Promise.reject("ไม่พบ Email นี้ในระบบ");
      } else if (err.response.data.message === "Email more than 1") {
        return Promise.reject("มี Email ซ้ำกันในระบบ กรุณาลองใหม่อีกครั้งภายหลัง");
      } else if (err.response.data.message === "Token ไม่ถูกต้อง") {
        return Promise.reject("Token ไม่ถูกต้อง");
      } else {
        return Promise.reject(JSON.stringify(err.message));
      }
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const changepass = async (data) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/changepass`,
      data: JSON.stringify({ user: data.user, pass: data.pass, manage: data.manage }),
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === "User and Pass require field") {
        return Promise.reject("User and Password require field");
      } else if (err.response.data.message === "User Not Found") {
        return Promise.reject("User Not Found");
      } else if (
        err.response.data.message === "User Not Match" ||
        err.response.data.message === "jwt must be provided" ||
        err.response.data.message === "invalid token" ||
        err.response.data.message === "jwt expired" ||
        err.response.data.message === "jwt malformed"
      ) {
        return Promise.reject(err.response.data.message);
      } else if (err.response.data.message === "Change Password 400 Bad request") {
        return Promise.reject("");
      } else {
        return Promise.reject(JSON.stringify(err.message));
      }
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const editprofile = async (data) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/edit-profile`,
      data: JSON.stringify(data),
    });
    console.log(response);
    if (data.pass) {
      let response_changepass = await API({
        method: "post",
        headers: { "Content-Type": "application/json", Authorization: token },
        url: `${URL_ENDPOINT_API}/changepass`,
        data: JSON.stringify({ user: data.user, pass: data.pass }),
      });
      console.log(response_changepass);
    }
    return response.data;
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === "Edit Profile Role 400 Bad request") {
        return Promise.reject("Edit Profile Role Error");
      } else if (err.response.data.message === "User Not Found") {
        onLogout();
      } else if (
        err.response.data.message === "User Not Match" ||
        err.response.data.message === "jwt must be provided" ||
        err.response.data.message === "invalid token" ||
        err.response.data.message === "jwt expired" ||
        err.response.data.message === "jwt malformed"
      ) {
        onLogout();
      } else if (err.response.data.message === "Change Password 400 Bad request") {
        return Promise.reject("");
      } else {
        return Promise.reject(JSON.stringify(err.message));
      }
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const deactivateUser = async (data) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/deactivate-user`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === "user require field") {
        return Promise.reject("user require field");
      } else if (err.response.data.message === "User Not Found") {
        onLogout();
      } else if (
        err.response.data.message === "User Not Match" ||
        err.response.data.message === "jwt must be provided" ||
        err.response.data.message === "invalid token" ||
        err.response.data.message === "jwt expired" ||
        err.response.data.message === "jwt malformed"
      ) {
        onLogout();
      } else if (err.response.data.message === "Deactivate User 400 Bad request") {
        return Promise.reject("Deactivate User 400 Bad request");
      } else {
        return Promise.reject(JSON.stringify(err.message));
      }
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const isLogin = () => {
  let user = localStorage.getItem("tok");
  let check = false;
  if (user) {
    check = true;
  }
  return { status: check };
};

const rolePermission = (userRoles, role_list) => {
  if (!role_list || role_list.length === 0) return true;
  if (userRoles.includes("admin")) return true;
  let role = userRoles;
  let check = false;
  for (let i = 0; i < role_list.length; i++) {
    for (let j = 0; j < role.length; j++) {
      if (role_list[i] === role[j]) {
        check = true;
        break;
      }
    }
  }
  return check;
};

const onLogout = () => {
  localStorage.clear();
  navigate("/login");
};

const sendEmail = async (data) => {
  try {
    let response = await API({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${URL_ENDPOINT_API}/send-email-contact`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

const getInitData = async (url) => {
  try {
    let token = localStorage.getItem("tok");
    let response = await axios.get(`${URL_ENDPOINT_API}/calldata/${url}`, { headers: { "Content-Type": "application/json", Authorization: token } });
    return response.data;
  } catch (err) {
    if (err.message === "Network Error") {
      return Promise.reject({ message: "ไม่สามารถเชื่อมต่ออินเตอร์เน็ต" });
    } else if (err.response.status === 401) {
      return Promise.reject({ message: "Token Error 401" });
    } else {
      return Promise.reject(err);
    }
  }
};

const getInitPage = async (url) => {
  try {
    let token = localStorage.getItem("tok");
    console.log("token", `${token}`);
    let response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      url: `${URL_ENDPOINT_API}/initpage/${url}`,
      data: { timestamp: +new Date() },
    });
    return response.data;
  } catch (err) {
    return { ok: false };
  }
};

const getRecaptcha = async () => {
  try {
    let response = await axios.get(`${URL_ENDPOINT_API}/recaptcha`);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const downloadFileExcel = async (filename) => {
  try {
    let token = localStorage.getItem("tok");
    axios({
      url: `${URL_ENDPOINT_API}/download-excel/${filename}`, //your url
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (err) {
    console.log("err", err);
    if (err.message === "Network Error") {
      return Promise.reject({ message: "ไม่สามารถเชื่อมต่ออินเตอร์เน็ต" });
    } else if (err.response.status === 401) {
      onLogout();
      return Promise.reject({ message: "Token Error 401" });
    } else {
      return Promise.reject(err);
    }
  }
};

const resetPassByEmail = async (data, token) => {
  try {
    let response = await API({
      method: "post",
      headers: { "Content-Type": "application/json", authorization: token },
      url: `${URL_ENDPOINT_API}/resetpass-by-email`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err) {
    let error_message = null;
    if (err.response) {
      error_message = err.response.data.message.split("|");
      return Promise.reject(error_message.filter((item) => item !== ""));
    } else {
      return Promise.reject(JSON.stringify(err.message));
    }
  }
};

export default {
  getUserForManage,
  getBorder,
  getSchool,
  getUserInfo,
  getActiveRole,
  changeUserRole,
  getProfileDb,
  auth,
  redirectLineLogin,
  redirectLineConnect,
  authLineGetProfile,
  authLineToEnable,
  getRecaptcha,
  register,
  registerCaptcha,
  registerByEmail,
  sendMailRegister,
  changepass,
  editprofile,
  resetpass,
  deactivateUser,
  isLogin,
  rolePermission,
  onLogout,
  sendEmail,
  getInitData,
  getInitPage,
  downloadFileExcel,
  resetPassByEmail,
};
